<template>
  <div>
    <v-row class="sticky-filters top-60">
      <v-col cols="2">
        <base-task-selector
          :initial-value="{ label: 'master',
                            value: 'master',}"
          :items="typeItems"
          :type="'type'"
          @select="selectFilter"
        />
      </v-col>
      <v-col cols="2">
        <base-credentials-selector
          v-if="['slave', 'master'].includes(chosenFilters.type)"
          :initial-value="{_id: emailsFilters.credentials.value}"
          @select="selectFilter"
        />
      </v-col>
      <v-col cols="2">
        <base-workflows-selector
          v-if="['slave'].includes(chosenFilters.type)"
          :initial-value="{_id: tasksFilters.workflow.value}"
          @select="selectFilter"
        />
        <v-text-field
          v-if="['master'].includes(chosenFilters.type)"
          v-model="chosenFilters['cred_integration']"
          v-debounce:300ms="searchEmail"
          label="Integration id or email"
          hide-details="auto"
        />
      </v-col>

      <v-col cols="2">
        <v-text-field
          v-if="['slave'].includes(chosenFilters.type)"
          v-model="chosenFilters['profile_id']"
          v-debounce:300ms="searchEmail"
          label="Profile id"
          hide-details="auto"
        />
      </v-col>
      <v-col cols="2">
        <v-text-field
          v-if="['slave'].includes(chosenFilters.type)"
          v-model="chosenFilters['master_task']"
          v-debounce:300ms="searchEmail"
          label="Master task id"
          hide-details="auto"
        />
      </v-col>
      <v-col cols="2">
        <base-task-selector
          :items="statusItems"
          :type="'status'"
          @select="selectFilter"
        />
      </v-col>
    </v-row>
    <v-row
      v-if="['slave'].includes(chosenFilters.type)"
      class="sticky-filters top-120"
    >
      <v-col cols="3">
        <v-text-field
          v-model="chosenFilters['cred_integration']"
          v-debounce:300ms="searchEmail"
          label="Integration id or email"
          hide-details="auto"
        />
      </v-col>
      <v-col cols="3">
        <v-text-field
          v-model="chosenFilters['dash_task']"
          v-debounce:300ms="searchEmail"
          label="Dash task id"
          hide-details="auto"
        />
      </v-col>
      <v-col cols="3">
        <v-text-field
          v-model="chosenFilters['email']"
          v-debounce:300ms="searchEmail"
          label="Email id"
          hide-details="auto"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="4">
        <v-timeline clipped>
          <div
            v-for="(task, i) of tasksList"
            :key="task._id"
            @click="selectEmail(i)"
          >
            <task-item
              :task="task"
              :active="selectedTask._id === task._id"
            />
          </div>
        </v-timeline>
      </v-col>
      <v-col cols="6">
        <prism-editor
          v-model="selectedTaskForEditor"
          :readonly="true"
          class="my-editor"
          :class="{'top-250': ['slave'].includes(chosenFilters.type), 'top-150': ['master'].includes(chosenFilters.type)}"
          :highlight="highlighter"
        />
      </v-col>
      <v-progress-linear
        v-if="loading"
        color="deep-purple accent-4"
        indeterminate
        rounded
        height="6"
      />
    </v-row>
  </div>
</template>

<script>
  import TaskItem from '@/components/tasksPage/TaskItem';
  import BaseCredentialsSelector from '@/components/base/selectors/BaseCredentialsSelector';
  import BaseWorkflowsSelector from '@/components/base/selectors/BaseWorkflowsSelector';

  import BaseTaskSelector from '@/components/base/selectors/BaseTaskSelector';
  import { mapGetters } from 'vuex';
  import { PrismEditor } from 'vue-prism-editor';
  import 'vue-prism-editor/dist/prismeditor.min.css';

  import { lazyLoadMixin } from '@/mixins/lazyLoadMixin';
  import { getTasks } from '../api/emailsWorkflowMethods';

  import { highlight, languages } from 'prismjs/components/prism-core';
  import 'prismjs/components/prism-clike';
  import 'prismjs/components/prism-javascript';
  import 'prismjs/themes/prism-tomorrow.css';

  export default {
    name: 'EmailsWorkflowPage',
    components: {
      TaskItem,
      PrismEditor,
      BaseCredentialsSelector,
      BaseTaskSelector,
      BaseWorkflowsSelector,
    },
    mixins: [lazyLoadMixin],
    data () {
      return {
        selectedTaskForEditor: 'Select email',
        selectedTask: '',
        emailFilter: '',
        statusItems: [
          {
            label: 'all status',
            _id: '',
          },
          {
            label: 'cancelled',
            _id: 'cancelled',
          },
          {
            label: 'scheduled',
            _id: 'scheduled',
          },
          {
            label: 'in_progress',
            _id: 'in_progress',
          },
          {
            label: 'failed',
            _id: 'failed',
          },
          {
            label: 'done',
            _id: 'done',
          },
        ],
        typeItems: [
          {
            label: 'master',
            _id: 'master',
          },
          {
            label: 'slave',
            _id: 'slave',
          },
        ],

        tasksList: [],

        chosenFilters: {
          type: 'master',
        },
      };
    },
    computed: {
      ...mapGetters({
        emailsFilters: 'emails/filters',
        tasksFilters: 'tasks/filters',
      }),
    },
    methods: {
      async getTasks (lazyload = false) {
        this.loading = true;
        try {
          const count = lazyload ? this.tasksList.length : 0;
          const response = await getTasks(this.chosenFilters, count);
          response.forEach(task => (task.type = 'send_email'));
          if (lazyload) {
            this.tasksList = [...this.tasksList, ...response];

            this.canLoadMore = response.length;
          } else {
            this.canLoadMore = true;
            this.tasksList = response;
          }
        } catch (e) {
          this.$noty.error(e.message);
          console.error(e);
        } finally {
          this.loading = false;
        }
      },
      async handleScroll () {
        const bottomOfWindow = Number(document.documentElement.scrollTop.toFixed()) + Number(window.innerHeight.toFixed()) >=
          document.documentElement.offsetHeight;

        if (bottomOfWindow && this.canLoadMore && !this.loading) {
          await this.getTasks(true);
        }
      },
      highlighter (code) {
        return highlight(code, languages.js); // returns html
      },
      selectEmail (index) {
        this.selectedTask = this.tasksList[index];
        this.selectedTaskForEditor = JSON.stringify(
          this.tasksList[index],
          null,
          '\t'
        ).replace(/"([^"]+)":/g, '$1:');
      },
      async selectFilter (value, filter) {
        console.log(this.chosenFilters);
        if (filter === 'type') {
          if (value.label === 'master') {
            delete this.chosenFilters.master_task;
            delete this.chosenFilters.profile_id;
            delete this.chosenFilters.workflows;
          }
        }
        if (filter === 'credentials') {
          await this.$store.dispatch('workflows/SET_NO_STATS_WORKFLOWS', value._id);
        }
        this.chosenFilters[filter] = value._id;
        await this.getTasks(false);
      },
      async searchEmail () {
        await this.getTasks(false);
      },
    },
  };
</script>

<style scoped>
.my-editor {
  background: #2d2d2d;
  color: #ccc;

  font-family: Fira code, Fira Mono, Consolas, Menlo, Courier, monospace;
  font-size: 14px;
  line-height: 1.5;
  padding: 25px;

  position: fixed;
  height: 75vh;
  width: 60vw;
  z-index: 1;
  white-space: pre-wrap;
}

.prism-editor__textarea:focus {
  outline: none;
}
.sticky-filters {
  position: sticky;
  margin-left: 8px;
  background: #fff;
  z-index: 3;
}
.top-250 {
  top: 250px;
}
.top-150 {
  top: 150px;
}
.top-120 {
  top: 120px;
}
.top-60 {
  top: 60px;
}
</style>
