import { useHttp } from '@/middleware/httpMiddleware';

async function getTasks (filter, skip = 0) {
  const data = await useHttp(
    `/integrations/admin/tasks/${filter?.type}`,
    'GET',
    null,
    {
      skip,
      credentials: filter.credentials || '',
      status: filter.status || '',
      workflow: filter.workflows || '',
      profile: filter.profile_id || '',
      scheduled_by: filter.master_task || '',
      email: filter.email || '',
      dash_task: filter.dash_task || '',
      cred_integration: filter.cred_integration || '',
    },
    false
  );

  return data.payload;
}

async function getTaskInfo (spaceInfo, userInfo, type, taskId) {
  const data = await useHttp(
    '/profiles/emails',
    'GET',
    null,
    {}
  );

  return data.payload;
}

export { getTasks, getTaskInfo };
